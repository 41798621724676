const left_img = document.getElementById('left');
const right_img = document.getElementById('right');
const left_container = document.getElementById('left_space');
const right_container = document.getElementById('right_space');


// Funzioni per gestire l'evento 'mouseenter' e 'mouseleave'
function mouseEnterLeft() {
    console.log("mouse_in");
    left_container.classList.remove('my_col_6');
    left_container.classList.add('col_left');
    right_container.classList.remove('my_col_6');
    right_container.classList.add('col_right_small');
    right_img.style.opacity = '0.2';
}

function mouseEnterRight() {
    console.log("mouse_in");
    right_container.classList.remove('my_col_6');
    right_container.classList.add('col_right');
    left_container.classList.remove('my_col_6');
    left_container.classList.add('col_left_small');
    left_img.style.opacity = '0.2';
}

function mouseLeaveLeft() {
    console.log("mouse_out");
    left_container.classList.remove('col_left');
    left_container.classList.add('my_col_6');
    right_container.classList.remove('col_right_small');
    right_container.classList.add('my_col_6');
    right_img.style.opacity = '0.8';
}

function mouseLeaveRight() {
    console.log("mouse_out");
    left_container.classList.remove('col_left_small');
    left_container.classList.add('my_col_6');
    right_container.classList.remove('col_right');
    right_container.classList.add('my_col_6');
    left_img.style.opacity = '0.8';
}

// Aggiungi i listener degli eventi se la larghezza della finestra è superiore a 768px
if (window.matchMedia("(min-width: 768px)").matches) {
    left_img.addEventListener('mouseenter', mouseEnterLeft);
    right_img.addEventListener('mouseenter', mouseEnterRight);
    left_img.addEventListener('mouseleave', mouseLeaveLeft);
    right_img.addEventListener('mouseleave', mouseLeaveRight);
}

// Aggiungi un listener per l'evento 'resize' per gestire i cambiamenti della larghezza della finestra
window.addEventListener('resize', function() {
    if (window.matchMedia("(min-width: 768px)").matches) {
        left_img.addEventListener('mouseenter', mouseEnterLeft);
        right_img.addEventListener('mouseenter', mouseEnterRight);
        left_img.addEventListener('mouseleave', mouseLeaveLeft);
        right_img.addEventListener('mouseleave', mouseLeaveRight);
    } else {
        left_img.removeEventListener('mouseenter', mouseEnterLeft);
        right_img.removeEventListener('mouseenter', mouseEnterRight);
        left_img.removeEventListener('mouseleave', mouseLeaveLeft);
        right_img.removeEventListener('mouseleave', mouseLeaveRight);
    }
});

// Aggiungi transizioni CSS ai tuoi contenitori per un effetto più "smooth"
[left_container, right_container].forEach(function(container) {
    container.style.transition = 'all 1.2s cubic-bezier(0.785, 0.135, 0.15, 0.86)';
});




//CANCELLA LEFT TITLE QUANDO PASSO SUL LATO DESTRO

document.getElementById("right_space").addEventListener("mouseover", function() {
    document.getElementById("left_title").style.visibility = "hidden";
    document.getElementById("left_title").style.opacity = "0";
    document.getElementById("left_title").style.transition = "visibility 0s 0.5s, opacity 0.5s linear";
    document.getElementById("left_down_title").style.visibility = "hidden";
    document.getElementById("left_down_title").style.opacity = "0";
    document.getElementById("left_down_title").style.transition = "visibility 0s 0.5s, opacity 0.5s linear";
});

document.getElementById("right_space").addEventListener("mouseout", function() {
    document.getElementById("left_title").style.visibility = "visible";
    document.getElementById("left_title").style.opacity = "1";
    document.getElementById("left_title").style.transition = "visibility 0s 0.5s, opacity 0.5s linear";
    document.getElementById("left_down_title").style.visibility = "visible";
    document.getElementById("left_down_title").style.opacity = "1";
    document.getElementById("left_down_title").style.transition = "visibility 0s 0.5s, opacity 0.5s linear";
});

document.getElementById("left_space").addEventListener("mouseover", function() {
    document.getElementById("right_title").style.visibility = "hidden";
    document.getElementById("right_title").style.opacity = "0";
    document.getElementById("right_title").style.transition = "visibility 0s 0.5s, opacity 0.5s linear";
    document.getElementById("right_down_title").style.visibility = "hidden";
    document.getElementById("right_down_title").style.opacity = "0";
    document.getElementById("right_down_title").style.transition = "visibility 0s 0.5s, opacity 0.5s linear";
});

document.getElementById("left_space").addEventListener("mouseout", function() {
    document.getElementById("right_title").style.visibility = "visible";
    document.getElementById("right_title").style.opacity = "1";
    document.getElementById("right_title").style.transition = "visibility 0s 0.5s, opacity 0.5s linear";
    document.getElementById("right_down_title").style.visibility = "visible";
    document.getElementById("right_down_title").style.opacity = "1";
    document.getElementById("right_down_title").style.transition = "visibility 0s 0.5s, opacity 0.5s linear";
});


//gestore link

document.addEventListener('DOMContentLoaded', (event) => {
    // Quando l'utente fa clic su un pulsante...
    document.querySelectorAll('.lang_button').forEach(function(button) {
        button.addEventListener('click', function() {
            console.log('funzione partita');
            var lang = this.value;  // Ottieni la lingua del pulsante premuto
            select_language(lang);  // Chiama la tua funzione con la lingua selezionata
        });
    });

    let arrUrls = {
        'ita': {
            url_hotel: 'https://hotellamaison.it/',
            url_camping: 'https://campingalleghe.it/'
        }
    };

    function select_language(lang) {
        let link_hotel = '';
        let link_camping = '';
    
        if (lang == 'eng') {
            link_hotel = 'https://hotellamaison.it/en/';
            link_camping = 'https://campingalleghe.it/en/';
        } else if(lang == 'deu') {
            link_hotel = 'https://hotellamaison.it/de/';
            link_camping = 'https://campingalleghe.it/de/';
        } else {
            link_hotel = 'https://hotellamaison.it';
            link_camping = 'https://campingalleghe.it/';
        }
    
        // Aggiorna l'array con i nuovi link
        arrUrls[lang] = {
            url_hotel: link_hotel,
            url_camping: link_camping
        };
    
        console.log('funzione!');
        console.log(arrUrls);
    

        // Aggiorna l'attributo href dei tag <a>
        document.querySelector('a.title_container.hotel').href = arrUrls[lang].url_hotel;
        document.querySelector('#left_space .img_box').href = arrUrls[lang].url_hotel;
        document.querySelector('a.title_container.camping').href = arrUrls[lang].url_camping;
        document.querySelector('#right_space .img_box').href = arrUrls[lang].url_camping;
    }
});



//effetto all'avvio del sito

window.addEventListener('load', function() {
    document.body.classList.add('loaded');
  });

// Magic mouse

if ( document.documentElement.clientWidth > 1000 ) {
	var options = {
		"hoverEffect": "circle-move",
		"hoverItemMove": false,
		"defaultCursor": true,
		"outerWidth": 20,
		"outerHeight": 20 
	};

	magicMouse(options);
}  